import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
// import Header from "../components/header/main"
import Header from "../components/header/header"
import Footer from "../components/footer"
import { FaAngleRight } from "react-icons/fa"
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri"

const IndexPage = ({ data, location }) => {
  return (
    <div>
      <Helmet htmlAttributes={{ lang: "fr" }}>
        <meta charSet="utf-8" />
        <title>Rénovation Thomy - peintre toulouse</title>
        <link rel="canonical" href="https://thomyrenovation.com" />
        <meta
          name="description"
          content="société spécialisée dans la peinture en batiment et la rénovation de murs et plafonds "
        />
      </Helmet>
      <Header location={location} />
      <div className="flex flex-col">
        <div className="h-[500px] lg:h-[600px] xl:h-[700px] 2xl:h-[800px] flex items-center justify-start relative overflow-hidden max-w-none bg-white">
          <div className="relative w-3/4 sm:w-1/2 z-10 flex items-center flex-col ml-4">
            <div className="flex flex-col justify-items-start">
              <Link to="/categorie/activites">
                <h1 className="text-slate-100 uppercase text-4xl tracking-tight font-extrabold sm:text-5xl lg:text-8xl">
                  Rénovation <br />
                  Thomy
                </h1>
                <button className="w-fit shadow-md rounded-full bg-white p-2 text-center mt-12 font-semibold text-base md:text-lg">
                  <div className="flex items-center flex-wrap">
                    <span className="whitespace-nowrap">
                      en savoir plus sur mes activités
                    </span>
                    <FaAngleRight />
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <video
            className="absolute w-full h-full object-cover"
            loop
            muted
            autoPlay
            controls=""
          >
            <source
              src={data.videoPro.publicURL}
              alt="video activites"
              type="video/mp4"
            />
          </video>
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40 z-0"></div>
        </div>
      </div>

      <div className="max-w-5xl mx-auto px-2 sm:px-6 lg:px-8 pt-10 pb-10">
        <h1 className="text-slate-800 text-2xl py-4 sm:text-4xl tracking-tight font-extrabold  lg:text-6xl">
          <RiDoubleQuotesL />
          <div className="flex justify-center">
            La peinture mon savoir-faire, <br />
            mon expertise, mon metier,
            <br /> pour réaliser vos projets.
          </div>
          <div className="flex justify-end">
            <RiDoubleQuotesR className="text-right" />
          </div>
        </h1>
      </div>
      <div className="flex h-fit md:h-[350px] lg:h-[400px] xl:h-[500px] flex-col sm:flex-row p-4  ">
        <div className="relative w-full h-full mb-2 mr-0 sm:mr-2 sm:mb-0 ">
          <img
            alt="découvrire l'équipe"
            src={data.imgAbout.childImageSharp.fluid.src}
            className="object-cover overflow-hidden h-full w-full rounded-2xl"
          />
          <div className="flex justify-end w-full">
            <div className="absolute bottom-0 h-full">
              <div className="h-full flex justify-center items-end flex-col mr-6">
                <Link to="/a-propos" className="text-right">
                  <h2 className="text-slate-100 text-4xl tracking-tight font-extrabold sm:text-4xl md:text-5xl lg:text-6xl text-right">
                    Découvrir
                    <br />
                    L'équipe
                  </h2>
                  <button className=" shadow-md rounded-full border-solid border-2 bg-white text-black p-2 text-center mt-4  font-semibold">
                    <div className="flex items-center">
                      <span>en savoir plus</span>
                      <FaAngleRight />
                    </div>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative w-full h-full mt-2 sm:mt-0 ml-0 sm:ml-2 sm:mb-0 ">
          <img
            alt="contact"
            src={data.imgAbout.childImageSharp.fluid.src}
            className="object-cover overflow-hidden h-full w-full rounded-2xl"
          />
          <div className="absolute bottom-0 w-full h-full bg-blue-900 rounded-2xl">
            <div className="flex justify-center h-full w-full">
              <div className="h-full flex justify-center items-start flex-col ml-6">
                <Link to="/contact">
                  <h2 className="text-slate-100 text-4xl tracking-tight font-extrabold sm:text-4xl md:text-5xl lg:text-6xl text-left">
                    Un projet à<br /> réaliser ?
                  </h2>
                  <button className=" shadow-md rounded-full border-solid border-2 bg-white text-black p-2 text-center mt-4  font-semibold">
                    <div className="flex items-center">
                      <span>contactez-moi</span>
                      <FaAngleRight />
                    </div>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query {
    videoPro: file(relativePath: { eq: "assets/renovation-paul.mp4" }) {
      publicURL
    }
    videoPart: file(relativePath: { eq: "assets/video-part.mp4" }) {
      publicURL
    }
    imgAbout: file(relativePath: { eq: "assets/a-propos.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default IndexPage
